import { css } from "@emotion/react";

export const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  button {
    font-family: inherit;
  }
`;
