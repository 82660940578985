import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import Cropper from "react-easy-crop";
import getCroppedImg from "../utils/cropImage";
import { useSnackbar } from "notistack";
import { uploadImageToR2, addMealHistory } from "../services/mealService";

interface MealDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (croppedImage: string, mealTime: string) => void;
}

const MealDialog: React.FC<MealDialogProps> = ({ open, onClose, onSave }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<any>(null);
  const [mealTime, setMealTime] = useState<string>(
    new Date().toISOString().slice(0, 16)
  );
  const { enqueueSnackbar } = useSnackbar();

  const isSaveDisabled = !imageSrc;

  useEffect(() => {
    if (open) {
      const japanTime = new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 16);
      setMealTime(japanTime);
      setImageSrc(null);
    }
  }, [open]);

  const handleCropComplete = (
    croppedAreaPercentage: any,
    croppedAreaPixels: any
  ) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageDataUrl = URL.createObjectURL(file);
      setImageSrc(imageDataUrl);
    }
  };

  const handleSave = async () => {
    try {
      if (imageSrc && croppedArea) {
        // 画像をクロップ
        const croppedImageUrl = await getCroppedImg(imageSrc, croppedArea);

        // ここで string を Blob に変換
        const response = await fetch(croppedImageUrl);
        const croppedImageBlob = await response.blob();

        // R2に画像をアップロードし、URLを取得
        const imageUrl = await uploadImageToR2(croppedImageBlob);

        // 仮のデータで保存
        await addMealHistory({
          imageUrl,
          mealTime,
          userId: "仮のユーザーID", // 実際のユーザーIDを使用すること
          mealName: "仮の料理名", // 仮のデータ
          mealGenre: "仮のジャンル", // 仮のデータ
          mealCalorie: 500, // 仮のデータ
        });

        enqueueSnackbar("食事履歴の追加に成功しました。", {
          variant: "success",
        });
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("食事履歴の追加に失敗しました。", { variant: "error" });
    }
  };

  const handleClose = () => {
    setImageSrc(null);
    setMealTime(new Date().toISOString().slice(0, 16));
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.2rem",
          mt: 2,
        }}
      >
        食事履歴を追加
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#ccc",
            mt: 1,
          }}
        />
      </DialogTitle>

      <DialogContent>
        {/* 日時入力 */}
        <Typography sx={{ mb: 1 }}>食事日時</Typography>
        <TextField
          type="datetime-local"
          value={mealTime}
          onChange={(e) => setMealTime(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* 画像選択 */}
        <Typography sx={{ mb: 1 }}>料理画像</Typography>
        <Box
          sx={{
            width: "100%",
            height: 200, // 高さを少し狭くする
            border: "2px #999", // よりモダンなボーダー
            borderRadius: "8px", // 少し角丸に
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer", // クリック可能なUIにする
            "&:hover": {
              backgroundColor: "#f0f0f0", // ホバー時の背景色変更
            },
            mb: 2,
          }}
          onClick={() => document.getElementById("imageInput")?.click()}
        >
          {imageSrc ? (
            <Box
              sx={{ position: "relative", width: "100%", height: 300, mt: 12 }}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </Box>
          ) : (
            <Typography>ここをクリックして画像を選択</Typography>
          )}
          <input
            id="imageInput"
            type="file"
            accept="image/*"
            hidden
            onChange={handleImageChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={
            {
              /* ボタンスタイル */
            }
          }
        >
          閉じる
        </Button>
        <Button
          onClick={handleSave}
          disabled={isSaveDisabled} // 画像が選択されていない場合無効
          sx={{
            backgroundColor: isSaveDisabled ? "#ccc" : "rgba(99, 180, 255)",
            color: "white",
            "&:hover": {
              backgroundColor: isSaveDisabled
                ? "#ccc"
                : "rgba(99, 180, 255, 0.8)",
            },
          }}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealDialog;
