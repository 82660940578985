import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Restaurant, Star } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TabBar: React.FC = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(
    window.location.pathname === "/meal-history" ? 0 : 1
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/meal-history");
    } else if (newValue === 1) {
      navigate("/rating-list");
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        padding: "2.2rem 0", // タブバー上下にスペースを追加
      }}
    >
      <BottomNavigationAction
        label="食事履歴"
        icon={<Restaurant />}
        sx={{
          minWidth: 0,
          "& .MuiBottomNavigationAction-label": {
            fontSize: "1rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
          },
        }}
      />
      <BottomNavigationAction
        label="評価履歴"
        icon={<Star />}
        sx={{
          minWidth: 0,
          "& .MuiBottomNavigationAction-label": {
            fontSize: "1rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem",
          },
        }}
      />
    </BottomNavigation>
  );
};

export default TabBar;
