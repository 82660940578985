const API_URL = "https://gourmet-recommender.noise06kmy69.workers.dev"; // Cloudflare WorkersのURL

export const signUp = async (name: string, email: string, password: string) => {
  try {
    const body = JSON.stringify({ name, email, password });
    console.log("Request Body:", body); // リクエストボディをログ出力して確認

    const response = await fetch(`${API_URL}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password }),
      mode: "cors",
    });

    if (!response.ok) {
      let errorData;
      try {
        errorData = await response.json();
      } catch (error) {
        throw new Error(`Error: ${response.status}, Failed to parse JSON`);
      }
      throw new Error(errorData.message || `Error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("SignUp API Error:", (error as Error).message);
    return { success: false, message: (error as Error).message };
  }
};

export const login = async (email: string, password: string) => {
  try {
    const response = await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      mode: "cors",
      credentials: "same-origin",
    });

    // レスポンスが正常でない場合
    if (!response.ok) {
      let errorData;
      try {
        errorData = await response.json();
      } catch (error) {
        throw new Error(`Error: ${response.status}, Failed to parse JSON`);
      }
      throw new Error(errorData.message || `Error: ${response.status}`);
    }

    // 正常なレスポンスの処理
    return await response.json();
  } catch (error) {
    console.error("Login API Error:", (error as Error).message);
    return { success: false, message: (error as Error).message };
  }
};
