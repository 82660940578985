import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Divider,
  Chip,
  IconButton,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountInfo from "../components/AccountInfo";
import TabBar from "../components/TabBar";
import MealDialog from "../components/MealDialog";
import { fetchMealHistory } from "../services/mealService";
import { useAuth } from "../contexts/AuthContext";

interface MealHistory {
  image: string;
  mealTime: string;
  mealName: string;
  genre: string;
}

const MealHistoryPage: React.FC = () => {
  const [mealHistory, setMealHistory] = useState<MealHistory[]>([]);
  const [open, setOpen] = useState(false);
  const { userId } = useAuth();

  // 食事履歴をフェッチする
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetchMealHistory(userId as string); // userId が null でないことを確認
        if (Array.isArray(response)) {
          setMealHistory(response);
        } else {
          console.error("取得した食事履歴が配列ではありません:", response);
          setMealHistory([]); // デフォルトで空の配列を設定
        }
      } catch (error) {
        console.error("食事履歴の取得に失敗しました:", error);
        setMealHistory([]);
      }
    };

    if (userId) {
      fetchHistory();
    }
  }, [userId]);

  const handleAddMeal = (croppedImage: string, mealTime: string) => {
    const newMeal = {
      image: croppedImage,
      mealTime,
      mealName: "醤油とんこつラーメン", // ダミーデータ
      genre: "ジャンルタグ", // ダミーデータ
    };
    setMealHistory([...mealHistory, newMeal]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("ja-JP", options);
  };

  return (
    <Box sx={{ pb: 8 }}>
      <Box
        sx={{
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          pb: 2,
          zIndex: 1,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AccountInfo />
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mt: 0,
            fontWeight: "bold",
            fontSize: "1.6rem",
          }}
        >
          食事履歴
        </Typography>
      </Box>

      {mealHistory.length === 0 ? (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
          食事履歴がありません
        </Typography>
      ) : (
        <Grid container spacing={2} sx={{ p: 2 }}>
          {mealHistory.map((meal, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  border: "2px solid rgba(204, 204, 204, 0.6)",
                  borderRadius: "24px",
                  height: "288px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: "12px", // 画像周りに余白を追加
                  }}
                >
                  <CardMedia
                    component="img"
                    image={meal.image}
                    alt="Meal Image"
                    sx={{
                      height: "160px",
                      width: "100%",
                      borderRadius: "16px", // 画像を角丸にする
                      objectFit: "cover", // 画像を中央に表示
                      aspectRatio: "16 / 9", // 16:9のアスペクト比を維持
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    height: "auto",
                    flex: 1,
                    p: "0px 12px 0px 12px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: "1rem", fontWeight: "bold", mb: 0 }}
                  >
                    {meal.mealName}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Chip
                    label={meal.genre}
                    sx={{
                      fontSize: "0.8rem",
                      height: "20px",
                      mb: 0.5,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 0.5,
                    }}
                  >
                    <IconButton sx={{ p: 0 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ ml: 1, color: "#999", fontSize: "0.85rem" }}
                    >
                      {formatDate(meal.mealTime)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      position: "absolute",
                      bottom: "8px",
                      right: "12px",
                      fontSize: "1.6rem",
                      color: "black",
                      mb: 1,
                      mr: 0.6,
                    }}
                  >
                    300kcal
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 80, right: 16 }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>

      <TabBar />
      <MealDialog open={open} onClose={handleClose} onSave={handleAddMeal} />
    </Box>
  );
};

export default MealHistoryPage;
