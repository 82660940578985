export const uploadImageToR2 = async (croppedImage: Blob): Promise<string> => {
  const fileName = `${Date.now()}-meal-image.jpg`; // 一意のファイル名を生成
  const r2Url = `https://fe15c1d8776aafda099b24d8637790a5.r2.cloudflarestorage.com/meal-history-images/${fileName}`;

  // R2に画像をPUTリクエストでアップロード
  const response = await fetch(r2Url, {
    method: "PUT",
    headers: { "Content-Type": "image/jpeg" },
    body: croppedImage,
    mode: "cors",
  });

  if (!response.ok) {
    throw new Error("R2ストレージへの画像アップロードに失敗しました");
  }

  return r2Url; // アップロード後の画像URLを返す
};

// D1データベースに食事履歴を保存
export const addMealHistory = async (mealData: {
  imageUrl: string;
  mealTime: string;
  userId: string;
  mealName: string;
  mealGenre: string;
  mealCalorie: number;
}) => {
  const response = await fetch(
    "https://gourmet-recommender.noise06kmy69.workers.dev/meal-history/add",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(mealData),
    }
  );

  if (!response.ok) {
    throw new Error("食事履歴の保存に失敗しました");
  }

  return await response.json();
};

export const fetchMealHistory = async (userId: string) => {
  const response = await fetch(
    `https://gourmet-recommender.noise06kmy69.workers.dev/meal-history?userId=${userId}`
  );

  if (!response.ok) {
    throw new Error("食事履歴の取得に失敗しました");
  }

  return await response.json();
};
