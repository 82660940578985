import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccountInfo from "../components/AccountInfo"; // アカウント情報表示コンポーネント
import TabBar from "../components/TabBar"; // タブバーコンポーネント
import GenreSelectionDialog from "../components/GenreSelectionDialog"; // ダイアログコンポーネント
import { useSnackbar } from "notistack"; // スナックバーを使用
import { fetchUserRatings } from "../services/ratingService";
import { useAuth } from "../contexts/AuthContext";

const RatingListPage: React.FC = () => {
  const [ratings, setRatings] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ログインしているユーザーの評価を取得
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        if (userId) {
          console.log("UserId:", userId); // userIdが正しく取得できているか確認
          const response = await fetchUserRatings(userId);
          if (response.success) {
            setRatings(response.ratings || []);
          } else {
            enqueueSnackbar("評価履歴の取得に失敗しました", {
              variant: "error",
            });
          }
        }
      } catch (error) {
        enqueueSnackbar("評価履歴の取得に失敗しました", { variant: "error" });
        console.error(error);
      }
    };
    if (userId) {
      fetchRatings();
    }
  }, [userId, enqueueSnackbar]);

  return (
    <Box sx={{ pb: 8 }}>
      <Box
        sx={{
          backgroundColor: "white", // 背景を白に
          position: "sticky",
          top: 0,
          pb: 2,
          zIndex: 1,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // 薄い影を追加
        }}
      >
        {/* アカウント情報の表示 */}
        <AccountInfo />
        {/* 評価一覧のタイトル */}
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            mt: 0,
            fontWeight: "bold",
            fontSize: "1.6rem",
            position: "sticky",
          }}
        >
          評価一覧
        </Typography>
      </Box>
      {/* 評価カードを表示 */}
      {ratings.length === 0 ? (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
          評価履歴がありません
        </Typography>
      ) : (
        ratings.map((rating, index) => (
          <Card
            key={index}
            sx={{
              ml: 2,
              mr: 2,
              mb: 2,
              mt: 2,
              border: "1px solid rgba(204, 204, 204, 0.6)",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "24px",
            }}
          >
            <CardContent>
              {/* 時間表示 */}
              <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                {new Date(rating.created_at).toLocaleString()}{" "}
                {/* 例: 2024年10月5日 12:34 */}
              </Typography>
              <Divider sx={{ my: 1 }} /> {/* グレーの線 */}
              {/* 空腹度 */}
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                空腹度
              </Typography>
              <Chip
                label={
                  rating.hunger_level === 1
                    ? "小"
                    : rating.hunger_level === 2
                    ? "中"
                    : "大"
                }
                sx={{ mt: 1, backgroundColor: "#f0f0f0" }}
              />
              {/* 食べたいジャンル */}
              <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2 }}>
                食べたいジャンル
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                {JSON.parse(rating.genres).map((genre: string, i: number) => (
                  <Chip key={i} label={genre} />
                ))}
              </Box>
            </CardContent>
          </Card>
        ))
      )}

      {/* 右下の追加ボタン */}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 80, right: 16 }} // タブバーの上に配置
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>

      {/* タブバー */}
      <TabBar />

      {/* ダイアログ */}
      <GenreSelectionDialog open={open} onClose={handleClose} />
    </Box>
  );
};

export default RatingListPage;
